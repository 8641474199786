@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Passions+Conflict&family=Lato&family=Roboto+Condensed&family=Poppins:wght@300&family=Phudu&family=Arimo:wght@400&display=swap');

.App {
  text-align: center;
 
}
:root{
  overflow-x: hidden;
  overflow-y: auto;
  --appBlue: #565494;
  --appGreen:  #7FA58E;
}
/**/
/*admin upload file*/
.upload-wrapper{
  position: relative;
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  clip-path: circle(50%);
  border: 1px solid grey;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  transform: scale(1);
  transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
  perspective: 2000px;
  transform-style: preserve-3d;
}
.upload-wrapper:hover{ 
  background-color: rgba(255, 255, 255, 0.812);
  transition: background-color 0.25s ease-in-out;
}

.upload-input{
  opacity: 0%;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%; 
  top: 50%;
  z-index: 10;
}
.upload-input:hover{
  cursor: pointer;
}
.error-wrapper{
  display: flex;
  width: 50%;
  margin: 0 auto;
  position: relative;
}
.file-input-info{
  position: absolute;
  top: 25%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.file-btn-fake{
  font-size: smaller;
  display: block;
  width: 60%;
  border-radius: 5%;
  height: fit-content;
  position: absolute;
  transform: translateZ(10px);
  top: 50%;
  padding: 0.5em;
  background: linear-gradient(217deg, rgba(0, 255, 234, 0.8), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(0, 187, 255, 0.8), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(125, 125, 187, 0.8), rgba(0,0,255,0) 70.71%);
  margin: 0 auto;
}

.file-btn-fake:hover{
  transform: scale(1.25);
}
.upload-logo-img{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: rgba(255, 255, 255, 0.08);
  mix-blend-mode: color; 
  transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
}

.upload-logo-img:hover{
  background-color: white;
  mix-blend-mode: color;
  transform: scale(1.25);
}

button.del-btn, button.update-btn, button.abort-btn{
  border-radius: 10px;
  outline: none;
  width: 80%;
  height: 40px; 
  min-width: fit-content;
  font-size: 1.2em;
  font-family: 'Roboto condensed';
  font-weight: 500;
  margin: 1em;
  border: none;
}
button.del-btn{
  background-color: rgb(197, 11, 11);
  color:  #c6fada;
}
.edit-btn{  
  border-radius: 10px;
  outline: none;
  width: 100px;
  height: 40px; 
  min-width: fit-content;
  font-size: 1.2em;
  font-family: 'Roboto condensed';
  font-weight: 500;
  margin: 1em;
  border: none;
  background-color: var(--appBlue); 
  color:  #c6fada;
}
.update-btn{  
  background-color: var(--appBlue);  
  color:  #c6fada;
  
}
.abort-btn{ 
  background-color: #7FA58E;  
  color:  #c6fada;

}
button.del-btn:hover{
  color: var(--appDarkGold);
}
/*admin actu*/
.page-adminactu-title{
  
}
.td-image{
  position: relative;
  width: 200px;
}
.article-details-ta{
  height: inherit;
  width: inherit;
  resize: none;
  border-radius: 10px;
  height: 80px;
  width: 70%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.foam-btn, .foam-btn.active{
  font-size: 25px;
  

}
.foam-btn{
  color: var(--appDarkGold);
}
.foam-btn.active{
  color: var(--appDarkGold);

}
.horse-option, .horse-option.active{
  height: 50px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid var(--appDarkGold);
  
}
.horse-option{
  background-color: white;
}
.horse-option.active{ 
 
  background-color: var(--appLightGold);
  filter: brightness(1.2); 
}
.article-column{
  display: flex;
  flex-direction: column;
  margin: 1em;
  width: 100%;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.articles-icone{
  margin-left: 2em;
  display: flex;
  font-size: 50px; 
  color: var(--appDarkGold);
}
.article-input{
  width: 100%;
  height: fit-content;
  line-height: 3em;
  border-radius: 10px;
  border: none;
  border-bottom: 1px solid #7FA58E;
  text-align: center;
  outline: none;
}
.article-input :active{
  background: transparent;
}
.articles-headers{
  text-transform: uppercase;
  color: #7FA58E;
  margin: 0 auto;
  margin-bottom: 2em;
}
.article-input.title{
  text-transform: uppercase;
  color: #7FA58E;
  font-size: larger;
}
.article-select{
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  border-radius: 10px;
  line-height: 2.5em;
  height: 2.5em;
  font-variant: small-caps;
}
.article-column-group{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}
.article-first{
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 5%;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  justify-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: fit-content;
}
.checkbox-publish{
  width: 20px;
  height: 20px;
 
}
.article-list{
  width: 100%;
  display: block;
  position: relative;
}
.actualities-container{
  width: 100%;
  display: block;
  position: relative;
}
.article-row{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.article-wrapper{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.article-block-wrapper{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.articles-container{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}
.article-btn-wrapper{
  display: grid;
  grid-template-columns: 10% 30% 30% 30%;
  justify-content: space-around;
  align-content: center;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  align-items: center;
  justify-items: center;
}
.date-article-title{
  padding: 1em;
  font-style: italic;
}
.title-group{
  display: grid;
  grid-template-columns: 55% 43%;
  column-gap: 2%;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  justify-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: fit-content;
}
.shown-group{
  display: contents;
}
.hidden-group{
  display: none;
}
.admin-image-table{
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/*Contact*/
.contact-form-container{
  display: grid;
  position: relative;
  grid-template-columns: 45% 45%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  width: 80%;
  margin: 0 auto;
}
.contact-image-wrapper{
  display: flex;
  width: 100%;
  border-right: 2px solid var(--appCaution);
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.photo-contact{
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0.5em;
  width: 80%;
  height: inherit;
  transform: scaleX(-1);
}
.contact-form-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  margin-right: 2em;
}
.contact-input-wrapper{
  display: flex;
  text-align: justify;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.input-label{
  color: var(--appDarkBlue);
  padding-bottom: 1em;
  
}
.form-input{
  border: none;
    border-bottom: 1px solid var(--appBlue);
    width: 100%;
    margin: 0 auto;
    line-height: 2em;
    background: transparent;
    margin: 0 auto;
    margin-bottom: 2em;
    width: 80%;
    display: block;
    position: relative;

}
/*Button Shiny*/
.btnshine-position{
  position: relative;

}

.btnshine-container {
  width: 100%;
  height: 100px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnshine-center {
  width: 180px;
  height: 60px;
  position: absolute;
}

.btnshine {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--appLightGold);
  outline: none;
  transition: 1s ease-in-out;
  font-family: 'Lato', sans-serif;
  
}

.btnshine svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: var(--appDarkGold);
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.btnshine:hover {
  transition: 1s ease-in-out;
 
}

.btnshine:hover svg {
  stroke-dashoffset: -480;
}

.btnshine span {
  color: var(--appDarkGold);
  font-size: 18px;
  font-weight: 100;
  
}

@media  (max-width: 910px){
  .article-btn-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 30% 30% 30%;
    justify-content: space-around;
    align-content: center;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    align-items: center;
    justify-items: center;
}
}