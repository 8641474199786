/*Css admin login*/
:root{
    --appBlue: #565494;
}

.login-form-wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center; 
    /* background: linear-gradient(217deg, rgba(247, 249, 249, 0.8), rgba(255,0,0,0) 70.71%), linear-gradient(127deg, rgba(181, 243, 248, 0.657), rgba(0,255,0,0) 70.71%), linear-gradient(336deg, rgba(101, 225, 247, 0.904), rgba(245, 245, 248, 0) 70.71%); */
    background-color: var(--appDarkBlue);
}
.login-form{
    height: inherit;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: space-around;
    align-items: flex-end;
}
.inputs-wrapper{
    padding: 5em;
    position: relative;
    display: block;
    border-radius: 3%;
    box-shadow: 2px 5px 10px #1d27347b;
    background-color: rgba(255, 255, 255, 1);
    width: 500px;
    height: 350px;
}
.year-month-buttons-container{
    
}
.input-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
}

.login-label{
padding: 1em;
color: var(--appDarkBlue);
}
.login-input{
    line-height: 2em;
    height: fit-content;
    padding: 0.8em;
    outline: none;
    border: none;
    border-radius: 30px;
    border-bottom: 1px solid #7FA58E;
  
}
.login-input:focus{
  /*   border: 1px solid var(--appCaution); */
  outline: 1px solid var(--appBlue);
}
.login-input:active{
    outline: 1px solid var(--appBlue);
}
.password-label{

}

button:hover{
    cursor: pointer;
    filter: brightness(1.2);
    color: #282c34;
    transition: filter 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login-button{
    color: var(--appDarkGold);
    font-variant: small-caps;
    font-weight: 500;
    letter-spacing: 1em;
    font-size: 18px;
    height: 50px;
    border-radius: 15px;
    border: 1px solid var(--appDarkGold);
    outline: 1px solid var(--appDarkGold);
    /* background: linear-gradient(217deg, rgba(11, 105, 51, 0.5), rgba(255,0,0,0) 70.71%), linear-gradient(127deg, rgba(11, 105, 51, 0.5), rgba(0,255,0,0) 70.71%), linear-gradient(336deg, rgba(40, 195, 226, 0.5), rgba(245, 245, 248, 0) 70.71%); */
    background: transparent;
}
.login-message{
    color: rgb(113, 25, 25);
}