
@keyframes slideOut{
    0% {      
      transform: translateX(0%);
    }   
    100% {       
        transform: translateX(100%);
    }
}
.points-wrapper{
    margin: 0 auto;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    text-align: right;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    flex-direction: row;
  }
 .moving-loading {
        animation: .5s linear 1s infinite alternate slideOut; 
        text-align: left;
        color: var(--appMainColor);
        font-size: 2em;
        padding: 0.1em;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    
    }
    .movingLoading:nth-child(2n){
        animation: .5s linear .5s infinite alternate slideOut; 
    }
    .follow-container{
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;
        font-size: 1.2em;
        color: var(--appMainColor);
    }
  