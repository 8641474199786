@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Passions+Conflict&family=Lato&family=Poppins:wght@300&&family=Phudu&display=swap');
:root{
    --appBoxShadow: 0 2px 4px rgba(86, 84, 148, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
  --appMainColor: #e7c861;
  --appLightColor: rgba(192, 193, 218, 1);
  --appTransColor: rgba(0, 0, 0, 0.2);
  --appDarkGold: #eabd3c;
  --appGold: #e7c861;
  --appExtraLightGold: #f0eac5;
  --appNavGold: #faf3c8eb;
  --appLightGold: #ebd79a;
  --appWhiteColor: rgba(255, 255, 255, 1);
  --appGreenColor: #7FA58E;
  --appDarkGreenColor: rgba(11, 116, 77, 0.2);
  --appWhiteTransColor: rgba(255, 255, 255, 0.8);
  --appLightGreenColor: #d0e5c97a;
  --appLightGreenColorPlain: #e1eedd;
  --appDarkBlue: rgb(22, 22, 106);
  --appBlue: #565494;
  --appLightBlue: #c0c1da;
}
.App {
  /*  max-width: 100vw;
   overflow-x: hidden; */
  }
  /*input*/
  .input-deco-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin: 1em;
  }

  .calendar-deco{
    font-size: 2em;
    color: var(--appDarkGold);
    margin-right: 1em;
    margin-left: 1em;
  }
  .input-date{
    line-height: 2em;
    border-radius: 20px;
    border: none;
    border-bottom: 1px solid #7FA58E;
    text-align: center;
    outline: none;
  }
  .input-datepicker-container{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .datepicker-container{
    display: block;
    position: relative;
    width: inherit;
  }
  .input-datepicker-wrapper{
    display: flex;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }
.calendar-container{
    position: absolute;
    z-index: 5;
    height: 300px;
    width: 900px;
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 5%;

}
.gonext{
    background-color: transparent;
    font-size: 2em;
    outline: none;
    border: none;
    color: #7FA58E;
}
.year-month-container{
    color: var(--appDarkBlue);
    font-weight: 600;
}
.goback{
    background-color: transparent;
    font-size: 2em;
    outline: none;
    border: none;
    color: #7FA58E;
}
.calendar-wrapper{
    display: block;   
    width: 100%;
    position: relative;
    margin: 0 auto;
}


.year-container{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    width: 100%;
}
.sliding-year-month-wrapper{
    transform: translatex(-100%);
}
.year-month-wrapper{
    
}
.year-month-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
}
.year-row{
    text-decoration: none;
    list-style: none;
    
}

.years-wrapper{
    display: flex;
    position: relative;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
}
.year-area{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    background: rgba(238, 130, 238, 0.406);
    margin-top: 1em;
    border: none;
    border-radius: 20px;
}
.no-year-area{
    text-decoration: none;
    list-style: none;
    opacity: 0;
}
.month-th-group{
    display: flex;
    flex-direction: row;
    background-color: var(--appWhiteColor);
}
.month-th-element{
    font-weight: 500;
    padding: 1em;
}
.month-container{
    display: block;
    position: relative;
}
.month-group{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    margin-left: 2em;
    margin-right: 2em;
    position: absolute;
    background-color: var(--appWhiteColor);
}
.ref-div{
    width: 50%;
    height: 300px;
    position: absolute;
    background-color: rgba(238, 130, 238, 0.406);
}
.hidden-month-group{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    margin-left: 2em;
    margin-right: 2em;
    opacity: 0.5;
}
.box-button{
    padding: 0.5em;
    line-height: 2em;
    background: var(--appWhiteColor);
    border: none;
    color: var(--appDarkBlue);
    font-weight: 600;
    font-size: 15px;
}

.box-button:hover{
    filter: brightness(1.5);
    background-color: var(--appLightBlue);
    color: var(--appDarkBlue);
}
.box-button:disabled{
    filter: brightness(1.5);
    background-color: var(--appWhiteColor);
    color: grey;
}
.box-button-clicked{
    padding: 0.5em;
    line-height: 2em;
    color: var(--appDarkBlue);
    filter: brightness(1.5);
    border-radius: 20px 0 0 20px;
    border: none;
    font-weight: 600;
    color: var(--appDarkGold);    
    background-color: rgba(8, 10, 66, 0.917);
}
.box-button-twice{
    padding: 0.5em;
    line-height: 2em;
    color: var(--appDarkBlue);
    filter: brightness(1.5);
    border-radius: 20px 20px 20px 20px;
    border: none;
    font-weight: 600;
    color: var(--appDarkGold);    
    background-color: rgba(8, 10, 66, 0.917);
}
.box-button-clicked-end{
    padding: 0.5em;
    line-height: 2em;
    filter: brightness(1.5);
    border-radius: 0px 20px 20px 0px;
    border: none;
    font-weight: 600;
    color: var(--appDarkGold);
    background-color: rgba(8, 10, 66, 0.917);
}
.box-button-over{
    padding: 0.5em;
    line-height: 2em;
    background-color:  rgba(147, 154, 247, 0.25);
    border: none;
    font-weight: 600;
}
.box-button-clicked:disabled{
    filter: brightness(1.5);
    background-color: var(--appWhiteColor);
    color: grey;
}
.box-button-clicked:active{
    filter: brightness(1.5);
    border-radius: 20px 0 0 20px;
    border: none;
    font-weight: 600;
    color: var(--appDarkGold);
    background-color: var(--appDarkBlue);
}
.box-button-clicked:hover{
    filter: brightness(1.5);
    border-radius: 20px 0 0 20px;
    border: none;
    font-weight: 600;
    color: var(--appDarkGold);
    background-color: var(--appDarkBlue);
}
.box-button-wrapper{
 display: grid;
 grid-template-columns: repeat(7, auto);
 grid-template-rows: repeat(6, auto);
 width: 100%;
 
}
.box-button-container{
    width: 100%;
    border: 1px solid var(--appDarkGold);   
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.year-month-buttons-container{
    display: flex;
    position: relative;
    flex-direction: row;
}
.year-month-wrapper{
    text-transform: capitalize;
}